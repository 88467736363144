import { Layout } from "antd"
import Head from "next/head"
import styled from "styled-components"
import {
  Banner,
  CompanyDetailSection,
  Footer,
  MultipleSectorComponent,
  OrganizationCultureSection,
  TechCompany,
} from "../components"
import { TechToolSectionComponent } from "../components/organisms/TechTool"
import { SuccessStories } from "../components/organisms/SuccessStories"
import { MediumSection } from "../components/organisms/MediumSection"
import { LocationSection } from "../components/organisms/LocationSection"

const StyledLayout = styled(Layout)`
  position: sticky;
  z-index: 2;
`

const Home = () => {
  return (
    <>
      <Head>
        <title>{"Home | TeamNepal "}</title>
      </Head>
      <Banner />
      <StyledLayout>
        <TechCompany />
        <MultipleSectorComponent />
        <OrganizationCultureSection />
        <TechToolSectionComponent />
        <CompanyDetailSection />
        <SuccessStories />
        <MediumSection />
        <LocationSection />
        <Footer />
      </StyledLayout>
    </>
  )
}

export default Home
